<template>
  <div class="banktransfers">
    <p class="path">Frequently Asked Questions > TayoCash Services > Bank Transfers</p>
    <h3>Bank Transfers</h3>

    <h4 id="bt1">How do I transfer funds to banks or other e-wallet accounts?</h4>
    <div class="paragraph">
      <p>You may transfer funds to <a class="h-violet" href="https://assets.prod.tayocash.com/forms/list_of_instapay_participating_banks.pdf">bank or other e-wallet accounts via InstaPay or PESONet</a> by following the steps below:
      </p>

      <ol>
        <li>Open your TayoCash app and click <span class="vtxt-align">Bank Transfer.</span></li>

        <li>Enter the <span class="vtxt-align">amount</span> you want to send.</li>

        <li>Scan or upload the <span class="vtxt-align">QR PH</span> code of the recipient OR manually input the transaction details. <br>
        <p>
        If you scan or upload the <span class="vtxt-align">QR PH</span> code, the details of the transaction will automatically be filled out in the TayoCash app.</p>
        <p>
        if you have no QR PH Code,</p>

        <ul class="loweralpha">
          <li>Select the Bank or E-wallet you want to send the funds to. </li>
          <li>Choose between Instapay or PESONet and enter the recipient’s bank or e-wallet details (Account Name, Account Number and Email Address, as well as Purpose of Transaction). </li>
        </ul>
        </li>

        <li>Click the Review button and check the details of the transaction. Once you’ve validated all information click Confirm.</li>

        <li>Upon the successful transfer, you may save the Transaction Receipt provided in the TayoCash app. You will also receive an SMS notification from TayoCash confirming the transaction.</li>
      </ol>
    </div>

    <h4 id="bt2">The funds I transferred were not reflected in the recipient’s bank or e-wallet account, what should I do?</h4>

    <div class="paragraph">
      <ol>
        <li>Please make sure that all the details of the transaction are complete and correct.</li>

        <li>Confirm if your TayoCash wallet balance was correctly deducted.</li>

        <li>
          <router-link class="link" :to="{ path: '/helpcenter', hash: '#submitticket' }">Submit a ticket</router-link> via the TayoCash <a class="link" href="/helpcenter">Help Center</a> in the app or website:
          <ul class="loweralpha">
            <li>Select <span class="vtxt-align">Bank Transfer Transaction</span></li>
            <li>Input the following details in the text field:
              <ul>
                <li>Bank Transfer via Instapay or PESONet</li>
                <li>Amount</li>
                <li>Date and Time of Transaction</li>
                <li>TayoCash Transaction Reference Number</li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>

      <p>A TayoCash Customer Care Representative will get back to you within 48 hours after you have submitted the ticket.</p>
    </div>

    <h4 id="bt3">The amount I transferred is incorrect. What should I do?</h4>
     <div class="paragraph">
       <ol>
         <li>If your fund transfer is less than the intended amount, kindly transact again using the TayoCash App.</li>

         <li>If your fund transfer is more than the intended amount, kindly coordinate with the recipient for the return of the excess amount.</li>
       </ol>
     </div>

    <h4 id="bt4">I transferred funds to an incorrect account. What should I do?</h4>
    <div class="paragraph">
      <ol>
        <li>Reminder: Please make sure that all the details of the transaction are complete and correct.</li>

        <li>You may coordinate directly with the account holder for the amount to be returned  to your account.</li>

        <li>
          <router-link class="link" :to="{ path: '/helpcenter', hash: '#submitticket' }">Submit a ticket</router-link> via the TayoCash <a class="link" href="/helpcenter">Help Center</a> in the app or website:
          <ul class="loweralpha">
            <li>Select <span class="vtxt-align">Fund Transfer Transaction</span></li>
            <li>Input the following details in the text field:
              <ul>
                <li>Bank Transfer via Instapay or PESONet</li>
                <li>Bank Name</li>
                <li>Amount</li>
                <li>Date and Time of Transaction</li>
                <li>TayoCash Transaction Reference Number</li>
                <li>Screenshot of the Issue (if available)</li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>

      <p>A TayoCash Customer Care Representative will extend any possible assistance.</p>
    </div>

    <h4 id="bt5">I have other concerns in using the Fund Transfer Service. What should I do?</h4>
    <div class="paragraph">
      <ol>
        <li>
          <router-link class="link" :to="{ path: '/helpcenter', hash: '#submitticket' }">Submit a ticket</router-link> via the TayoCash <a class="link" href="/helpcenter">Help Center</a> in the app or website:
          <ul class="loweralpha">
            <li>Select <span class="vtxt-align">Fund Transfer Transaction</span></li>
            <li>Input the following details in the text field:
              <ul>
                <li>Bank Transfer via Instapay or PESONet</li>
                <li>Amount</li>
                <li>Date and Time of Transaction</li>
                <li>TayoCash Transaction Reference Number</li>
                <li>Screenshot of the Issue</li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>

      <p>A TayoCash Customer Care Representative will get back to you within 48 hours after you have submitted the ticket.</p>

      <p>For other inquiries on or PESONet and InstaPay  FAQs, you may also visit the link <br>
      PESONet: <a target="_blank" class="link" href="https://www.bsp.gov.ph/PaymentAndSettlement/FAQ_PESONet.pdf">https://www.bsp.gov.ph/PaymentAndSettlement/FAQ_PESONet.pdf</a><br>
      Instapay: <a target="_blank" class="link" href="https://www.bsp.gov.ph/PaymentAndSettlement/FAQ_Instapay.pdf">https://www.bsp.gov.ph/PaymentAndSettlement/FAQ_Instapay.pdf</a>
      </p>
    </div>

    <h4 id="bt5">What are the cut-off periods for PESONet transactions?</h4>
    <div class="paragraph">
      <p>The following are the cut-off periods and corresponding crediting time for PESONet:</p>

      <table class="ui celled structured table unstackable feesandchargestable">
        <thead>
          <tr>
            <th>Cut-off Cycle</th>
            <th>Time</th>
            <th>Covered Transactions / Cut-off Schedule</th>
          </tr>
          
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>10:00 AM</td>
            <td>- 4:00:01 PM to 00:00:00 AM transactions from the previous day <br>
            - 00:00:01 AM to 10:00:00 AM transactions of the current business day
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>4:00 PM</td>
            <td>- 10:00:01 AM to 4:00:00 PM transactions of the current day</td>
          </tr>

        </tbody>

      </table>

      <p>PESONet is available during banking days. Transactions received after the 4PM cut-off, on holidays or weekends will be processed for transmission to the receiving bank on the next banking day. </p>
    </div>

    <h4 id="bt5">Will there be instances for unsuccessful credit to the receiving bank?</h4>
    <div class="paragraph">
      <p>The final credit to the beneficiary account may not be successfully processed by the receiving bank for several reasons, such as:</p>
      <ol>
        <li>
          Invalid beneficiary account number (may be incorrect, closed, dormant, blocked, frozen) 
        </li>
        <li>
          The currency of the beneficiary account is not a qualified PHP account 
        </li>
      </ol>
      <p>Please ensure to obtain the correct account information to avoid delays in the credit to the beneficiary account.</p>
    </div>

    <h4 id="bt5">Can I request for cancellation of the transaction?</h4>
    <div class="paragraph">
      Once your account has been debited, the transaction is considered final and can no longer be cancelled.
    </div>

    <h4 id="bt5">What is the difference between InstaPay and PESONet?</h4>
    <div class="paragraph">
      <p><b>InstaPay and PESONet Features</b></p>
      <p>A quick guide to help you choose which electronic fund transfer option is best for you.</p>

      <table class="ui celled structured table unstackable feesandchargestable">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>InstaPay</th>
            <th>PESONet</th>
          </tr>
          
        </thead>
        <tbody>
          <tr>
            <td>Transaction Amount Limit</td>
            <td>Php 50,000 per day per account but subject to TayoCash wallet limit.</td>
            <td>No maximum amount per transaction but subject to TayoCash wallet limit</td>
          </tr>
          <tr>
            <td>Availability of Service</td>
            <td>24x7</td>
            <td>Banking days only</td>
          </tr>
          <tr>
            <td>Availability of Funds to the Recipient's Account</td>
            <td>Real-time credit</td>
            <td>Same day subject to cut-off periods with corresponding crediting time of 10:00 AM and 4:00 PM</td>
          </tr>
          <tr>
            <td>Transaction Fee for Sending Funds</td>
            <td>Php 12 per credit transaction</td>
            <td>Php 5 per credit transaction</td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>
</template>
<script>
export default {
  name: 'banktransfers'
}
</script>

<style scoped lang='scss'></style>
